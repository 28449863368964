<form [formGroup]="sourceSftpProfileForm">
    <hr>
    <!-- Host -->
    <div class="form-group row mb5">
        <label for="sourceSftpProfileHost" class="col-sm-3 col-form-label">Host<span class="req-color">*</span></label>
        <div class="col-sm-6">
            <input type="text" formControlName="host" class="form-control" id="sourceSftpProfileHost"
                name="sourceSftpProfileHost" placeholder="Host">
            <div *ngIf="form.host.invalid && (form.host.dirty || form.host.touched)" class="alert alert-danger">
                <div *ngIf="form.host.errors?.['required']">Host is required.</div>
            </div>
        </div>
    </div>
    <!-- Port -->
    <div class="form-group row mb5">
        <label for="sourceSftpProfilePort" class="col-sm-3 col-form-label">Port<span class="req-color">*</span></label>
        <div class="col-sm-6">
            <input type="text" formControlName="port" class="form-control" id="sourceSftpProfilePort"
                name="sourceSftpProfilePort" placeholder="Port">                
            <div *ngIf="form.port.invalid && (form.port.dirty || form.port.touched)" class="alert alert-danger">
                <div *ngIf="form.port.errors?.['required']">Port is required.</div>
            </div>
        </div>
    </div>
    <!-- Path -->
    <div class="form-group row mb5">
        <label for="sourceSftpProfilePath" class="col-sm-3 col-form-label">Path</label>
        <div class="col-sm-6">
            <input type="text" formControlName="path" class="form-control" id="sourceSftpProfilePath"
                name="sourceSftpProfilePath" placeholder="Path">
        </div>
    </div>
    <!-- Archive path -->
    <div class="form-group row mb5">
        <label for="sourceSftpProfileArchivePath" class="col-sm-3 col-form-label">Archive path</label>
        <div class="col-sm-6">
            <input type="text" formControlName="archivePath" class="form-control" id="sourceSftpProfileArchivePath"
                name="sourceSftpProfileArchivePath" placeholder="Archive path">
        </div>
    </div>
    <!-- Encoding -->
    <div class="form-group row mb5">
        <label for="sourceSftpProfileEncoding" class="col-sm-3 col-form-label">Encoding<span
                class="req-color">*</span></label>
        <div class="col-sm-6">
            <input type="text" formControlName="encoding" class="form-control" id="sourceSftpProfileEncoding"
                name="sourceSftpProfileEncoding" placeholder="Encoding">
            <div *ngIf="form.encoding.invalid && (form.encoding.dirty || form.encoding.touched)"
                class="alert alert-danger">
                <div *ngIf="form.encoding.errors?.['required']">Encoding is required.</div>
            </div>
        </div>
    </div>
    <!-- Use RegEx -->
    <div class="form-group row mb5">
        <div class="col-sm-3 col-form-label"></div>
        <div class="col-sm-6">
            <div class="form-check">
                <input class="form-check-input" formControlName="useRegEx" type="checkbox"
                    id="sourceSftpProfileUseRegEx" value="" aria-label="...">
                <label class="form-check-label" for="sourceSftpProfileUseRegEx">
                    Use RegEx
                </label>
            </div>
        </div>
    </div>
    <!-- FilterOrRegEx -->
    <div class="form-group row mb5">
        <label for="sourceSftpProfileFilterOrRegEx" class="col-sm-3 col-form-label">Filter/RegEx</label>
        <div class="col-sm-6">
            <input type="text" formControlName="filterOrRegEx" class="form-control" id="sourceSftpProfileFilterOrRegEx"
                name="sourceSftpProfileFilterOrRegEx" placeholder="Filter/RegEx">
        </div>
    </div>
    <!-- Delete -->
    <div class="form-group row mb5">
        <div class="col-sm-3 col-form-label"></div>
        <div class="col-sm-6">
            <div class="form-check">
                <input class="form-check-input" formControlName="sourceSftpProfileDelete" type="checkbox"
                    id="sourceSftpProfileDelete" value="" aria-label="...">
                <label class="form-check-label" for="sourceSftpProfileDelete">
                    Delete
                </label>
            </div>
        </div>
    </div>

    <!-- Selection -->
    <div class="row mb5">
        <div class="col-sm-3"></div>
        <div class="col-sm-6">
            <div class="form-check">
                <input class="form-check-input" formControlName="useExistingKeyVaultValue" type="radio"
                    name="useExistingKeyVaultValue" [value]="1" id="useExistingKeyVaultValue">
                <label class="form-check-label" for="useExistingKeyVaultValue">
                    Use existing key vault secret
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" formControlName="useExistingKeyVaultValue" type="radio"
                    name="useExistingKeyVaultValue" [value]="2" id="useExistingKeyVaultValue" checked>
                <label class="form-check-label" for="useExistingKeyVaultValue">
                    Add key vault secret
                </label>
            </div>
            <div class="form-check" *ngIf="sourceSftpProfileForm.value.keyVaultProfileId != null && this.isEdit != false">
                <input class="form-check-input" formControlName="useExistingKeyVaultValue" type="radio"
                    name="useExistingKeyVaultValue" [value]="3" id="useExistingKeyVaultValue">
                <label class="form-check-label" for="useExistingKeyVaultValue">
                    Update key vault secret
                </label>
            </div>
        </div>
    </div>
    <!-- Username -->
    <div class="form-group row mb5">
        <label for="sourceSftpProfileUsername" class="col-sm-3 col-form-label">Username<span
                class="req-color">*</span></label>
        <div class="col-sm-6">
            <input type="text" formControlName="userName" class="form-control" id="sourceSftpProfileUsername"
                name="sourceSftpProfileUsername" placeholder="Username">
            <div *ngIf="form.userName.invalid && (form.userName.dirty || form.userName.touched)"
                class="alert alert-danger">
                <div *ngIf="form.userName.errors?.['required']">Username is required.</div>
            </div>
        </div>
    </div>
    <!-- Key vault secret -->
    <div class="form-group row mb5" *ngIf="keyVaultProfiles && (sourceSftpProfileForm.value.useExistingKeyVaultValue === 1  || sourceSftpProfileForm.value.useExistingKeyVaultValue === 3)">
        <label for="keyVaultSecretDDL" class="col-sm-3 col-form-label control-label">Key vault secret</label>
        <div class="col-sm-6">
            <select dropdown formControlName="keyVaultProfileId" id="keyVaultSecretDDL" name="keyVaultSecretDDL"
                class="form-select form-select-sm control-label">
                <option value="-1">--- Select secret ---</option>
                <option *ngFor="let item of keyVaultProfiles" [value]="item.id">
                    {{item.name}}
                </option>
            </select>
        </div>
    </div>
    <!-- Password -->
    <div class="form-group row mb5" *ngIf="sourceSftpProfileForm.value.useExistingKeyVaultValue === 2 || sourceSftpProfileForm.value.useExistingKeyVaultValue === 3">
        <label for="sourcePassword" class="col-sm-3 col-form-label">Password<span class="req-color">*</span></label>
        <div class="col-sm-6">
            <input type="password" formControlName="passPhrase" class="form-control" id="sourcePassword"
                name="sourcePassword" placeholder="Password">
        </div>
    </div>
    <!-- System name -->
    <div class="form-group row mb5" *ngIf="sourceSftpProfileForm.value.useExistingKeyVaultValue === 2">
        <label for="systemName" class="col-sm-3 col-form-label">System name<span class="req-color">*</span> <i
                class="fa fa-info-circle alfalaval-blue-color" aria-hidden="true" placement="top"
                ngbTooltip="The secret that will store the SFTP password has the form of al-goh-sftp-auth-in-{system name}-password. The value you provide in the System name input will be used to construct the secret name."></i></label>
        <div class="col-sm-6">
            <input type="text" formControlName="systemName" class="form-control" id="systemName" name="systemName"
                placeholder="System name">
        </div>
    </div>
</form>