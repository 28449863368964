export const environment = {
    production: false,
    apiBaseUrl: 'https://al-api-goh-preprod.azurewebsites.net/api/v1/',
    apiTrackingBaseUrl: 'https://al-api-goh-preprod.azurewebsites.net/api/v2/',
    apiPrevBaseUrl: 'https://al-api-goh-test.azurewebsites.net/api/v1/',
    adminToolClientId: '6b6dd235-d375-47f1-b1ce-508266b8624c',
    adminToolTenentId: 'ed5d5f47-52dd-48af-90ca-f7bd83624eb9',
    env: 'preprod',
    prevenv: 'test',
    d365EnricherHost: 'https://api-alfalaval-preprod.azure-api.net/api/v1/d365fo/D365FOLookupService',
    httpDestinationDefaultQueue: 'goh.transfer.send.http'
};
