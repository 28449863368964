import { Component, Input, OnInit} from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { KeyVaultService } from 'src/app/common-services/keyvault.service';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';


@Component({
  selector: 'source-ftp-input',
  templateUrl: './source-ftp.component.html'
})
export class SourceFtpComponent implements OnInit {
    @Input() formGroupName: string;
    @Input() isEdit: boolean;

    public sourceFtpProfileForm: FormGroup;
    public keyVaultProfiles: Array<KeyVaultProfile>;

    constructor(private keyVaultService: KeyVaultService, private rootFormGroup: FormGroupDirective) {}

    get form() { return this.sourceFtpProfileForm.controls; }

    ngOnInit(): void {
        this.sourceFtpProfileForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

        this.keyVaultService.getKeyVaultProfiles().subscribe({
            next: (result) => {
                this.keyVaultProfiles = result;
            },
            error: (error) => {
                console.error(error);
            }
        });
    }
}
